:root {
  --bs-body-font-family: Ubuntu, Helvetica, Arial, sans-serif;
  --bs-body-color: var(--white-100);
  --bs-body-bg: var(--bg-100);
  --bs-link-color-rgb: 255, 255, 255;
  --bs-emphasis-color-rgb: 255, 255, 255;

  --bg-30: #4E5466;
  --bg-40: #3A4051;
  --bg-50: #2B3242;
  --bg-60: #242A37;
  --bg-70: #1B202C;
  --bg-80: #161B26;
  --bg-90: #121620;
  --bg-100: #01030b;

  --white-40: #5A6178;
  --white-50: #707993;
  --white-70: #969CB1;
  --white-80: #B3B9CD;
  --white-90: #D0D5E5;
  --white-100: #eee;

  --primary-70: #4453AB;
  --primary-80: #4D5EBF;
  --primary-90: #516EBF;
  --primary-100: #6589EC;
  --primary-110: #95B1FF;

  --bs-primary-rgb: 101, 137, 236;
  --bs-success-rgb: 93, 222, 167;
  --bs-danger-rgb: 226, 101, 100;
  --bs-warning-rgb: 255, 209, 90;
  --bs-secondary-rgb: 22, 27, 38;
  --bs-primary: rgba(var(--bs-primary-rgb), 1);
  --bs-secondary: rgba(var(--bs-secondary-rgb), 1);
  --bs-secondary-bg: var(--bs-secondary);
  /*--bs-secondary-bg: var(--bg-60);*/
  --bs-success: rgba(var(--bs-success-rgb), 1);
  --bs-yellow: rgba(var(--bs-warning-rgb), 1); /*#FFD15A;*/
  --bs-danger: rgba(var(--bs-danger-rgb), 1); /*#E26564;*/
  --bs-red: #FA2F2F;

  --bs-border-width: 0;
  --bs-border-radius: 0.625rem;
  --padding: 1rem;

  --sidebar-width: 268px;

  --bs-breakpoint-xxl: 1600px;
}

.form-control {
  --bs-body-bg: var(--bg-80);
}
.form-check-input {
  --bs-form-check-bg: var(--bg-60);
  /*--bs-border-width: 1px; --bs-border-color: var(--bg-60);*/
}
.form-check-input:checked[type=radio] {
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%236589EC'/%3e%3c/svg%3e");
}
.form-switch .form-check-input, .form-switch .form-check-input:focus {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23707993'/%3e%3c/svg%3e");
}
.form-switch .form-check-input:checked {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23eee'/%3e%3c/svg%3e");
}

.btn {
  --bs-btn-font-weight: 700;
  --bs-btn-padding-x: 1.25rem; --bs-btn-padding-y: 0.75rem;
}
/*.btn.disabled, .btn:disabled, fieldset:disabled .btn {}*/
.btn-xss {
  --bs-btn-padding-x: 0.5rem; --bs-btn-padding-y: 0.25rem;
  --bs-border-radius: 0.25rem;
  --bs-btn-font-size: 10px; --bs-btn-line-height: 12px;
}
.btn-xs {
  --bs-btn-padding-x: 0.75rem; --bs-btn-padding-y: 0.25rem;
  --bs-border-radius: 0.25rem;
  --bs-btn-font-size: 12px; --bs-btn-line-height: 16px;
}
.btn-sm {
  --bs-btn-padding-x: 1rem; --bs-btn-padding-y: 0.5rem;
  --bs-border-radius-sm: 1.25rem;
}
.btn-md {
  --bs-btn-padding-x: 1rem; --bs-btn-padding-y: 0.5rem;
  --bs-border-radius: 0.5rem;
}
.btn-primary, .btn-secondary, .btn-default {
  --bs-btn-color: var(--white-100); --bs-btn-hover-color: var(--white-90); --bs-btn-active-color: var(--white-80);
}
.btn-primary {
  --bs-btn-bg: var(--primary-100); --bs-btn-hover-bg: var(--primary-90); --bs-btn-active-bg: var(--primary-80); --bs-btn-disabled-bg: var(--primary-80);
}
.btn-secondary {
  --bs-btn-bg: var(--bg-80); --bs-btn-hover-bg: var(--bg-90); --bs-btn-active-bg: var(--bg-90);
}
.btn-default {
  --bs-btn-bg: var(--bg-60); --bs-btn-hover-bg: var(--bg-70); --bs-btn-active-bg: var(--bg-70);
}
.btn-border {
  --bs-btn-border-width: 1px;
}
.btn-checkbox {
  --bs-btn-color: var(--white-50);
}
.btn-checkbox.btn-secondary {
  --bs-btn-border-color: var(--bg-80);
}
.btn-light {
  --bs-btn-bg: var(--white-100); --bs-btn-hover-bg: var(--white-90); --bs-btn-active-bg: var(--white-80);
  --bs-btn-color: var(--bg-100); --bs-btn-hover-color: var(--bg-50); --bs-btn-active-color: var(--bg-40);
}
.btn-danger {
  --bs-btn-bg: rgba(245, 116, 105, 1); --bs-btn-hover-bg: var(--bs-btn-bg); /*--bs-btn-bg: var(--bs-danger);*/
  --bs-btn-color: var(--bg-100); --bs-btn-hover-color: var(--bg-100);
}
.btn-warning {
  --bs-btn-bg: var(--bs-yellow); --bs-btn-hover-bg: var(--bs-yellow);
  --bs-btn-color: var(--bg-100);
}
.btn-outline-warning {
  --bs-btn-bg: var(--bg-40);
  --bs-btn-color: var(--bs-yellow);
}
.btn-outline-danger {
  /*--bs-btn-bg: var(--bg-80);*/
  --bs-btn-bg: #28222B;
  --bs-btn-color: var(--bs-danger);
}
.btn-close {
  --bs-btn-close-opacity: 1;
  --bs-btn-close-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23707993'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e");
}
.btn-service {
  --bs-btn-bg: var(--bg-30); --bs-btn-hover-bg: var(--bg-40); --bs-btn-active-bg: var(--bg-50);
}
.btn-success {
  --bs-btn-bg: var(--bs-success); --bs-btn-hover-bg: var(--bs-success); --bs-btn-active-bg: #41A78D; /*--bs-btn-hover-bg: #41A78D; --bs-btn-active-bg: #43A078;*/
  --bs-btn-color: var(--bg-100); --bs-btn-hover-color: var(--bg-100); --bs-btn-active-color: var(--bg-100);
}
.btn-success.card-sign {
  --bs-btn-bg: #41A78D; --bs-btn-hover-bg: var(--bs-btn-bg); --bs-btn-active-bg: #41A78D;
  /*--bs-btn-color: var(--bg-100); --bs-btn-hover-color: var(--bg-100); --bs-btn-active-color: var(--bg-100);*/
}
.btn-service.btn-success {
  --bs-btn-bg: #274C46;
  --bs-btn-color: var(--white-100);
}

.navbar { --bs-navbar-color: inherit; }

.nav-tabs {
  --bs-nav-tabs-link-active-bg: transparent;
  --bs-nav-link-color: var(--white-50); --bs-nav-link-hover-color: var(--white-100); --bs-nav-tabs-link-active-color: var(--white-100);
  /*--bs-nav-tabs-border-width: 1px;*/
  /*--bs-nav-tabs-link-hover-border-color: var(--bg-70);*/
}

hr { --bs-border-width: 1px; }

.dropdown-menu {
  --bs-dropdown-bg: var(--bg-90); --bs-dropdown-link-hover-bg: var(--bg-90);
  --bs-dropdown-spacer: -0.5rem; --bs-dropdown-item-padding-y: 0.5rem;
  --bs-dropdown-border-width: 0; --bs-dropdown-border-radius: 0 0 var(--bs-border-radius) var(--bs-border-radius);
  --bs-dropdown-link-active-bg: inherit; --bs-dropdown-link-color: #5F7492;
}

.badge {
  --bs-badge-border-radius: 4px;
}

.progress, .progress-stacked {
  --bs-progress-bar-bg: var(--primary-100);
  --bs-progress-height: 1.25rem;
}
.progress-sm {
  --bs-progress-height: 0.5rem;
}
.progress-lg {
  --bs-progress-height: 2rem;
  --bs-progress-border-radius: 0.5rem;
}

.tooltip {
  --bs-tooltip-color: var(--white-100);
  --bs-tooltip-bg: var(--bg-50);
  --bs-tooltip-max-width: 340px;
  --bs-tooltip-opacity: 1;
  --bs-tooltip-padding-x: 1rem; --bs-tooltip-padding-y: 1rem;
}
/*.tooltip.md {
  --bs-tooltip-max-width: 334px;
}*/
/*.bs-tooltip-bottom-end {
  --bs-tooltip-arrow-width: 15px; --bs-tooltip-arrow-height: 18px;
}*/

.card {
  /*--bs-card-bg: var(--bg-90);*/
  --bs-card-inner-border-radius: 8px;
}

.g-4, .gx-4 {
  --bs-gutter-x: 20px;
}

.modal-backdrop {
  --bs-backdrop-opacity: 0.8;
}
.modal {
  /*--bs-modal-header-border-width: 0; --bs-modal-footer-border-width: 0;*/
  --bs-modal-bg: var(--bg-80);
  --bs-modal-border-radius: 10px;
  --bs-modal-padding: 1.25rem; --bs-modal-header-padding: var(--bs-modal-padding); --bs-modal-footer-gap: 0rem;
  --bs-modal-width: 400px;
}
.modal-sm {
  --bs-modal-width: 310px;
}

@media screen and (max-width: 991px) {
  .modal-pop {
    --bs-modal-width: 100%;
    --bs-modal-border-radius: 0;
    --bs-modal-margin: 0;
  }
}

@media screen and (min-width: 992px) {
  .tags .dropdown-menu {
    --bs-dropdown-bg: transparent;
    --bs-dropdown-spacer: 0;
    --bs-dropdown-padding-y: 0;
    --bs-dropdown-link-active-bg: var(--primary-100);
    --bs-dropdown-item-border-radius: var(--bs-border-radius);
  }

  .modal-lg-md {
    --bs-modal-width: 400px;
  }
}
