@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@400;700&display=swap');

@import "assets/css/theme.css";

a { text-decoration: none; }
p, .form-group { margin-bottom: 1.25rem; }
img { max-width: 100%; height: auto; }
.i { margin-right: 9px; }
.si { display: inline-block; vertical-align: middle; fill: currentColor; }
.si- { width: 16px; height: 16px; }
.si-sm { width: 12px; height: 12px; }
.si-md { width: 20px; height: 20px; }
.si-lg { width: 24px; height: 24px; }
.rotate-180 { transform: rotate(180deg); }
hr { border-color: var(--bg-60); opacity: 1; }
.mw-2 { max-width: 360px; margin: 0 auto; }
.fs-0 { font-size: 0; }
.lh-0 { line-height: 0; }
.display-3 { font-size: 32px; font-weight: inherit; }
.display-4 { font-size: 28px; }

.mb-2 { margin-bottom: 0.75rem !important; } /*12px*/
.mb-4 { margin-bottom: 1.25rem !important; } /*20px*/
.mb-5 { margin-bottom: 2rem !important; } /*32px*/
.mb-6 { margin-bottom: 2.5rem !important; } /*40px*/
.my-5 { margin: 2rem 0 !important; }
.my-6 { margin: 2.5rem 0 !important; }
/*.p-4 { padding: 1.25rem !important; }*/

.clear { clear: both; }
.note { color: var(--white-50); font-size: 0.75rem; }
.note.header { font-size: 15px; }
.note.agree { font-size: 11px; }
.note.agree a { color: #525558; font-size: 12px; }
.note a:hover { color: #3649b4; }
.link { text-decoration: underline; }
.marked { color: #4d5ebf; }
.marked-2 { color: #eee; }
.marked-3 { color: #e3626b; }
.marked-4 { color: #95B1FF; }
.notice { background: #1c1c33; }
.bordered { border-top: 1px solid #525558; }
.opacity-0 { opacity: 0; }
.subheader { color: var(--white-80); }
a.subheader:hover { color: var(--bs-link-color-rgb); }
.subtitle { color: var(--white-50); }
.paid .subtitle { color: #9D8D9C; }
svg-icon { display: inline-block; }
.eye { cursor: pointer; position: absolute; right: 14px; top: 10px }
.under { display: inline-block; }
.under:after { content: ''; display: block; height: 1px; background: linear-gradient(101.27deg, #25BA7B 0%, #4D5EBF 100%); }
.alert { margin-top: 1rem; }
.action { float: right; margin-top: 5px; }
/*.badge { position: absolute; font-size: 11px; font-weight: 300; border-radius: 50%; }*/
.gold { color: #FFD15A; }
.question { float: right; margin-top: -5px; margin-right: -16px; }
.bg-80 { background-color: var(--bg-80) }
.pre-line { white-space: pre-line }

.form-control { padding: 12px 20px; }
.form-control, .form-control:focus { color: #DEDFE9; height: 48px; font-size: 14px; }
.form-control.is-invalid, .was-validated .form-control:invalid { background-image: none; padding-right: 0.75rem; }
.form-control.is-invalid { color: #DA5685; border-color: #1C1C33; }
.form-control:disabled, .form-control[readonly] { background-color: #20202e; border-color: #20202e; }

.form-check-label { margin-bottom: 0; }
.form-check-input { cursor: pointer; width: 20px; height: 20px; }
.form-check-input:checked { background-color: var(--bg-60); border-color: #fff; }
.form-check-input:focus { box-shadow: none; }
.form-check { padding-left: 28px; min-height: 28px; margin-bottom: 0; }
.form-check .form-check-input { margin-left: -28px; }
.form-switch .form-check-input:checked { background-color: var(--primary-100); }

.btn-checkbox.active { color: var(--white-100); border-color: var(--primary-100) }
.btn-checkbox .form-check { padding-left: 0; min-height: 28px; margin-bottom: 0; display: flex; align-items: center; justify-content: space-between; }
.btn-checkbox .form-check .form-check-input { margin: 0; float: none; }

.form-control::placeholder { color: var(--white-50); }
.form-control::-webkit-input-placeholder { color: var(--white-50); }
.form-control::-moz-placeholder { color: var(--white-50); }
.form-control:-ms-input-placeholder { color: var(--white-50); }
.form-control:-moz-placeholder { color: var(--white-50); }

.btn-warning { box-shadow: 0 4px 0 0 #B89641; }
.btn-outline-warning { box-shadow: 0 2px 0 0 var(--bg-50); }
.btn-service { box-shadow: 0 4px 0 0 var(--bg-40); }
.btn-success { box-shadow: 0 4px 0 0 #43A078; }
.btn-service.btn-success { box-shadow: 0 4px 0 0 #1F3C37; }
.btn-danger { box-shadow: 0 4px 0 0 #B0544C; }

.btn-warning:hover, .btn-success:hover, .btn-service:hover, .btn-danger:hover { box-shadow: none; transform: translateY(4px); }

.btn-success.card-sign { box-shadow: none; transform: translateY(0); }

.btn-premium { background: linear-gradient(101.27deg, #E95691 0%, #FB8C26 100%); }

/*.btn-gradient, .btn-secondary.active { background: linear-gradient(101.27deg, #25BA7B 0%, #445093 100%); color: #fff; }
.btn-gradient .btn { padding: 4px 15px; border-radius: 100px; color: #4D5EBF; font-size: 12px; font-weight: 500; }*/

.btn-gradient { background: linear-gradient(101.27deg, #25BA7B 0%, #445093 100%); color: #fff; }
.btn-gradient .btn { padding: 4px 15px; border-radius: 100px; color: #4D5EBF; font-size: 12px; font-weight: 500; }

.btn-marked, .btn-marked:hover, .btn-marked:active, .btn-marked:focus { color: #95B1FF; }
.btn-back, .btn-back:hover, .btn-back:active, .btn-back:focus { color: var(--white-50); }

.btn-text { position: relative; font-size: 12px; text-align: left; line-height: 1.3; }
.btn-text:before { content: ""; position: absolute; top: 2px; left: 0; bottom: 2px; border-left: 5px solid #4d5ebf; border-top-left-radius: 10px; border-bottom-left-radius: 10px; }
.btn-text .float-end { height: 40px; margin-left: 10px; }
.btn-text .float-end svg { width: 16px; height: 100%; }

.btn-send { position: absolute; right: 0; top: 50%; margin-top: -24px; color: #fff; outline: none; }
.btn-send > i { margin: 0; }
.btn-send:hover { color: #3649b4; }

.btn-light.compact { border-radius: 100px; font-size: 14px; padding: 7px 10px; }


.btn-close, .btn-right { float: right; padding: 12px 16px; margin-right: -10px; margin-top: -10px; }

.alert-message { position: relative; background-color: var(--bg-90); color: var(--white-100); text-align: left; font-weight: 300; font-size: 14px; border: 0; border-radius: 10px; }
.alert-message strong { font-weight: 500; font-size: 16px; display: block; margin-bottom: 10px; }
.alert-message.read { background-color: #323359; }
.alert-message.read:before { content: ''; position: absolute; top: 10px; right: 10px; width: 6px; height: 6px; background-color: #4D5EBF; border-radius: 50%; }
.special { background: linear-gradient(101.27deg, #25BA7B 0%, #445093 100%); }
.alert-message.read.special:before { background-color: red; }

label { font-size: 16px; display: inline-block; margin-bottom: 0.5rem; }

.media { display: flex; }

h1, h2, h3, h4, h5, .h1, .h2, .h3, .h4, .h5 { margin-bottom: 1.25rem; }
h1, .h1 { font-size: 24px; }
h2, .h2 { font-size: 20px; }
h3, .h3 { font-size: 20px; }
h4, .h4 { font-size: 16px; margin-bottom: 12px; }
h5, .h5 { font-size: 14px; font-weight: 300; }

.invalid-feedback { font-weight: 400; color: #DA5685; font-size: 12px; }
.invalid-feedback > div { padding-left: 22px; background: url("/assets/img/icons/i.svg") no-repeat left center; }

.container { /*max-width: 1200px; */padding: 30px 20px; max-width: 100%; }
.container .container { padding-left: 0; padding-right: 0; }
.container.landing { padding-top: 20px; }

.wrap { display: flex; flex-direction: column; height: 100vh; }
/*.head, .foot { flex-shrink: 0; }*/

/*body.with-profile .main { max-width: calc(100% - 560px); }
.profile { flex: 1; order: 3; max-width: 50px; background-color: var(--bg-90); }
.profile.active { max-width: 330px; padding: 20px; }*/
.head { padding-top: 8px; }

.navbar { padding: 0; }

.navbar-dark .navbar-toggler { border: 0; }
.navbar-dark .navbar-toggler-icon { background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 1%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }
.navbar-dark .navbar-nav .nav-link { color: #5F7492; }

.nav-tabs .nav-link { border-bottom: 2px solid var(--bg-70); }
.nav-tabs .nav-link.active { border-color: var(--primary-100); }
.nav-tabs .nav-item { flex: 1; }
.nav-tabs .nav-item .nav-link { width: 100%; text-align: center }

.breadcrumb { background-color: transparent; padding-left: 0; padding-right: 0; font-size: 12px; }
.breadcrumb a, .breadcrumb-item.active { color: rgba(255,255,255,.5); }
.breadcrumb a:hover { color: rgba(255,255,255,.75); text-decoration: none; }

.row.mini { margin-right: -5px; margin-left: -5px; }
.row.mini > div { padding: 0 5px; }

.row.middle { margin-right: -10px; margin-left: -10px; }
.row.middle > div { padding: 0 10px; }

.page-info { font-weight: 500; }
.page-info p { margin-bottom: 15px; }

.card .card-img-overlay { top: auto; bottom: var(--padding); padding: inherit; left: var(--padding); right: var(--padding); }
.card hr, hr.br { border-top: 5px solid #fff; width: 16px; margin: 10px auto; }
.card-link { position: absolute; z-index: 1; left: 0; top: 0; width: 100%; height: 100%; }
.card-title { position: absolute; left: var(--padding); top: var(--padding); margin-top: 0; right: 30px; padding: inherit; font-size: 20px; font-weight: bold; }
.card-title p { margin-bottom: 8px; }
.card-title.overflow, .card-title .overflow { word-break: break-word; overflow: hidden; text-overflow: ellipsis; max-height: 60px; }
.card-sign { position: absolute; z-index: 1; top: var(--padding); right: var(--padding); }
.card-text { font-weight: 400; font-size: 14px; overflow: hidden; text-overflow: ellipsis; max-height: 63px; margin-bottom: 16px; }
.card-img { max-height: 160px; width: auto; display: block; margin: 0 auto 1rem auto; }
.card-body { background-color: var(--bg-90); border-radius: 8px; }
.card-body.page { padding: 20px; }
.card-body.page .card-img { margin-bottom: 16px; }

.page { background-color: var(--bg-90); }
.paid .card-body.page .thumb { filter: blur(5px); }

.grid-item { width: 100%; margin-bottom: 20px; }

pre { padding: 20px; white-space: pre-line; font-family: var(--bs-body-font-family) }
pre.body { background-color: var(--bg-90); font-size: 14px; color: var(--white-80); }
pre.body h5 { margin-bottom: 0; }

.video-container { overflow: hidden; position: relative; width: 100%; margin-bottom: 10px; }
.video-container::after { padding-top: 56.25%; display: block; content: ''; }
.video-container iframe, .video-container .video { position: absolute; top: 0; left: 0; width: 100%; height: 100%; }

.plyr__video-wrapper { background: #000; height: 100%; margin: auto; overflow: hidden; position: relative; width: 100%; z-index: inherit; }
.aplay { --plyr-color-main: #4d5ebf; --plyr-audio-controls-background: transparent; }
.plyr--audio .plyr__controls { padding: 0 !important; }
.plyr { border-radius: 0.5rem; }

.plyr-inline { position: relative; display: inline-block; }
.plyr-inline .plyr { position: static; --plyr-control-icon-size: 48px; --plyr-video-control-background-hover: transparent; }
.plyr-inline .plyr--video .plyr__controls { bottom: 50%; padding: 0; background: transparent; justify-content: space-around; }
.plyr-inline .plyr__controls .plyr__controls__item:first-child { margin-right: 0; }

.modal-dialog { max-width: var(--bs-modal-width); margin-right: auto; margin-left: auto; }
.modal-fullscreen { max-width: none; margin: 0; }

.modal-content .close { color: #fff; }
.modal-content .card-columns { column-count: 1; }
.modal-footer > * { margin: 0; }

/*.modal-fn { max-width: 415px; margin: 100px auto; }
.modal-fn .modal-content { border: 0; border-radius: 20px; background: url("/assets/img/bg/finish/b.png") no-repeat; background-size: cover; }
.modal-fn .modal-header { padding: 20px 20px 0; }
.modal-fn .modal-body { padding: 0 40px; }
.modal-fn .modal-footer { padding: 20px 40px 40px 40px; }*/

.modal-open-full { padding-right: 0 !important; }
.modal-open-full .main .container { display: none; }
.modal-open-full .main .modal.full .container { display: block; }
.modal.full { position: static; }
.modal.full .modal-dialog { max-width: 100%; width: 100%; margin: 0; }
.modal.full .modal-content { border: 0; border-radius: 0; background-color: transparent; }

.modal.fullscreen { background-color: var(--bs-body-bg); }
.modal.fullscreen .modal-content { background-color: transparent; }

.show-poster .plyr__poster { opacity: 1; }

.panel { border-radius: 0.5rem; background-color: var(--bg-60); padding: 1.25rem; display: flex; flex-direction: column; gap: 1.25rem; }
.panel hr { border-color: var(--bg-40) }

.panel-info { border-radius: 0.5rem; background-color: var(--bg-90); padding: 1rem; display: flex; flex-direction: column; gap: 0.75rem; }
.panel-info .subtitle { margin-bottom: 8px; color: var(--white-50); font-size: 14px; }
.panel-info hr { margin: 0; width: 100%; }

.panel-note { font-size: 10px; padding: 0.5rem; text-align: center; position: absolute; z-index: 1; left: 50px; right: 16px; top: calc(50% - 12px); transform: translateY(-50%) }

.progress-lg { font-weight: bold; font-size: 14px; }

.progress-bar.flash { position: relative; }
.progress-bar.flash:before { content: ''; position: absolute; top: 2px; left: 8px; background-color: #FFE8AC; right: 8px; height: 8px; border-radius: 20px; }

.row-gap { display: flex; align-items: center; gap: 1rem; align-self: stretch; }
.gap-0 { gap: 0 }
.gap-1 { gap: 0.5rem }
.gap-2 { gap: 0.75rem }

.rates { color: #FFD15A; display: flex; align-items: flex-start; gap: 2px; font-size: 0; }

/*.bs-tooltip-bottom-end { margin-right: 27px !important; }
.bs-tooltip-bottom-end .tooltip-arrow { margin-left: 23px; }
.bs-tooltip-bottom-end .tooltip-arrow::before { transform: rotate(25deg); bottom: -7px; }*/

.scrollable { overflow: hidden; overflow-x: auto; scrollbar-width: none; -ms-overflow-style: none; }
.scrollable::-webkit-scrollbar { display: none; width: 8px }

.tooltip-inner { text-align: left; line-height: 16px; }

.animate-flash { animation: flashAnimation 1s infinite }
@keyframes flashAnimation {
  0% { opacity: 1 }
  50% { opacity: 0.2 }
  100% { opacity: 1 }
}

.dots { white-space: break-spaces; }
.dots span { animation-name: blink; animation-duration: 1.4s; animation-iteration-count: infinite; animation-fill-mode: both; margin-left: 2px; display: inline-block; font-size: 1.1em }
.dots span:nth-child(2) { animation-delay: .2s }
.dots span:nth-child(3) { animation-delay: .4s }

@keyframes blink {
  0% { opacity: .2 }
  20% { opacity: 1 }
  to { opacity: .2 }
}

/*
xs: 0,
sm: 576px,
md: 768px,
lg: 992px,
xl: 1200px,
xxl: 1600px
*/

/* < lg */
@media screen and (max-width: 991px) {
  body.with-foot .content.logged { padding-bottom: 64px; }

  .dropdown-menu .active { display: none; }

  .card-body.page { background-color: transparent; padding: 0; }

  app-finish-training { height: 100%; display: flex; flex-direction: column; }

  .modal-pop .modal-dialog { min-height: 100%; align-items: flex-end; text-align: center; }

  body.with-foot .modal.fullscreen { height: calc(100% - 64px); }
  /*.modal-backdrop.fullscreen { height: calc(100vh - 64px); }*/
}

/*@media screen and (max-width: 767px) {
}*/

/* mob */
/*@media screen and (max-width: 575px) {
}*/



/* sm */
@media screen and (min-width: 576px) {
  .mb-sm-0 { margin-bottom: 0 !important; }

  .grid-item { width: calc(50% - 10px); }
}

/* md */
@media screen and (min-width: 768px) {
  .mb-md-6 { margin-bottom: 2.5rem !important; }
  .mb-md-0 { margin-bottom: 0 !important; }

  .head { position: absolute; top: 20px; right: 0; }
  .navbar-brand { display: none; }

  .video-container { margin-bottom: 20px; }
}

/* lg */
@media screen and (min-width: 992px) {
  .mb-lg-0 { margin-bottom: 0 !important; }
  .mb-lg-4 { margin-bottom: 1.25rem !important; }
  .my-lg-4 { margin: 1.25rem 0 !important; }
  .my-lg-5 { margin: 2rem 0 !important; }
  .mb-lg-6 { margin-bottom: 2.5rem !important; }

  .mw-lg-2 { max-width: 360px; margin: 0 auto; }

  .h-lg-100 { height: 100%; }

  .gap-lg-5 { gap: 32px !important; }

  .bg-lg-transparent { background-color: transparent }

  .card hr { margin-top: 20px; margin-bottom: 20px; }

  .btn-text { font-size: 14px; }

  h1, .h1 { margin-bottom: 2.5rem; }
  h4, .h4 { margin-bottom: 1.25rem; }

  .sidebar { order: 1; flex: 1; min-width: var(--sidebar-width); max-width: var(--sidebar-width); background-color: var(--bg-90); }
  .sidebar aside { position: fixed; left: 0; top: 0; height: 100%; overflow-y: auto; padding: 36px 0 36px 28px; background-color: var(--bg-90); width: var(--sidebar-width); }
  .content { display: flex; flex-direction: row; /*flex-wrap: wrap;*/ flex: 1 0 auto; /*height: 100vh;*/ }
  .main { flex: 2; order: 2; width: calc(100% - var(--sidebar-width)); }

  .container { padding: 40px 24px; }

  .tags .dropdown-menu { position: static; }
  .tags .dropdown-item:not(.active):not(:hover) { background-color: var(--bs-secondary); }
  .tags .navbar-nav { gap: 0.625rem; }

  .panel-lg { border-radius: 0.5rem; background-color: var(--bg-60); padding: 1.25rem; display: flex; flex-direction: column; gap: 1.25rem; }
  .bg-lg-secondary { background-color: var(--bs-secondary); }

  .form-block { max-width: 395px; }
  .form-block-lg { max-width: 580px; }

  .m-width-none { max-width: none; }

  .grid-item { width: calc(50% - 10px); }

  .modal.fullscreen { left: var(--sidebar-width); width: calc(100% - var(--sidebar-width)); }
  /*.modal-backdrop.fullscreen { left: var(--sidebar-width); }*/
  .modal-fullscreen { width: 100%; }
}

/* xl */
@media screen and (min-width: 1200px) {
  .mb-xl-6 { margin-bottom: 2.5rem !important; }
  .mb-xl-0 { margin-bottom: 0 !important; }

  .grid-item { width: calc(33.33% - 13px); }
}

/* xxl */
@media screen and (min-width: 1600px) {
  /*.mb-xxl-0 { margin-bottom: 0 !important; }*/

  /*gutter - gutter / cols*/
  .grid-item { width: calc(25% - 15px); }
}
